<template>
  <v-container>
    <v-row>
      <v-col md="8" cols="12" lg="8">
        <v-row>
          <v-col><schedules :class_id="class_id"></schedules></v-col>
        </v-row>
        <v-row>
          <v-col>
            <trial-details
              v-if="trialDetails.is_trial_user"
              :trialDetails="trialDetails"
              :roomId="class_info.room"
            ></trial-details>
          </v-col>
        </v-row>
        <v-row
          ><v-col><activities :class_id="class_id"></activities></v-col
        ></v-row>
      </v-col>
      <v-col md="4" cols="12" lg="4">
        <v-row>
          <v-col cols="12"
            ><class-description :class_info="class_info"></class-description
          ></v-col>
          <v-col cols="12">
            <upcoming-meetings :class_id="class_id"></upcoming-meetings
          ></v-col>
          <v-col cols="12">
            <user-tabs
              :class_id="class_id"
              :is_teacher="class_info.is_teacher"
            ></user-tabs>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TrialDetails from "@/components/room/TrialDetails.vue";
import Schedules from "@/components/profile/Schedules.vue";
import Activities from "@/components/room/Activities.vue";
import ClassDescription from "@/components/room/ClassDescription.vue";
import UserTabs from "@/components/profile/UserTabs.vue";
import UpcomingMeetings from "@/components/profile/UpcomingMeetings.vue";
export default {
  name: "Room",
  components: {
    Schedules,
    Activities,
    ClassDescription,
    UserTabs,
    UpcomingMeetings,
    TrialDetails,
  },
  data: () => ({
    dialog: false,
    class_info: {},
    trialDetails: {},
  }),
  async mounted() {
    await this.getClassInfo();
  },
  computed: {
    class_id() {
      return this.$route.params.id;
    },
  },
  methods: {
    getClassInfo() {
      return this.$axios
        .get(`/rooms/room-subjects/${this.class_id}`)
        .then((res) => {
          const { trial_details, ...class_info } = res.data;
          this.class_info = class_info;
          this.trialDetails = trial_details;
        });
    },
    closeDialog() {
      this.dialog = false;
    },
    getSubjectMeetings() {},
  },
};
</script>

<style></style>
