<template>
  <v-card
    :color="
      trialDetails.is_trial_expired ? 'red lighten-4' : 'yellow lighten-3'
    "
    class="pa-4"
  >
    <v-row>
      <v-col cols="12" md="6">
        <v-card-title class="text-h5">Trial Details</v-card-title>
        <v-card-text
          >Trial Expires at:
          {{
            moment(trialDetails.trial_expires_at).format(
              "MMMM Do YYYY, h:mm:ss a"
            )
          }}</v-card-text
        >
        <v-card-text
          >Remaining Trial Meetings:
          {{
            trialDetails.allowed_meetings - trialDetails.used_meetings
          }}</v-card-text
        >
        <v-card-text
          >Complete Payment to Join the Class.
          <router-link :to="{ name: 'payment', query: { room: roomId } }"
            ><b> Pay Now </b></router-link
          >
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    roomId: {
      type: String,
      required: true,
    },
    trialDetails: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>